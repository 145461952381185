import BaseModal from '@/components/base/baseModal';
import NumberInput from '@/components/features/featuresNumberInput';
import { createloanUrlApi, getCustomerServiceApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { Button, useDisclosure } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';
import BaseSignature from '@/components/base/baseSignature';
import {LoadingSvg} from "@/assets/icons/comm/LoadingSvg";
import {useModel} from "@umijs/max";
import { useMount } from 'ahooks';

/**
 *  Apply 申请借贷
 */
export default function TranchePrice(props: any) {
  const { onCancel, open, type, onSuccess } = props;

  const { t } = useTranslation();

  // 份额
  const [tranche, setTranche] = useState<any>('');

  useEffect(() => {
    if (open) {
      setTranche('');
    }
  }, [open]);

  // 防抖
  const [loading, setLoading] = useState(false);
  // 专属客服弹窗
  const { isOpen:customerIsOpen, onOpen:customerOnOpen, onClose:customerOnclose } = useDisclosure();
  const { appInfo } = useModel("appInfo")
  const { runAsync: createloan } = useReq(createloanUrlApi, {
    manual: true,
    onSuccess(data, params) {
      toast.success(t('提交成功，等待审核'));
      // 打开专属客服
      // customerOnOpen()
      onCancel && onCancel();
    },
  });
  // 签名
  const { isOpen, onOpen, onClose } = useDisclosure();

  // 判断是否输入份额
  const valid = () => {
    return new Promise<void>((resolve, reject) => {
      if (!tranche) {
        toast.error(t('请输入金额'));
        reject({ message: t('请输入金额') });
      } else if (tranche <= 0) {
        toast.error(t('请输入金额'));
        reject({ message: t('请输入金额') });
      }
      resolve();
    });
  };
  // 获取贷款专属客服
  const {run:getCustomerServiceReq,data:customerServiceData = { } as any} = useReq(getCustomerServiceApi,{
    manual:true
  })
  useMount(()=>{
    // getCustomerServiceReq({thirdType:1,usePosition:1})
  })
  const onSubmit = async (data = {}) => {
    try {
      await valid();
      setLoading(true);
      await createloan({
        type: type,
        cost: tranche,
        ...data
      })
      setLoading(false)
    } catch (e: any) {}
  };

  return (
    <>
      <BaseModal placement="center" title={t("在线客服")} isOpen={customerIsOpen} onClose={customerOnclose}>
        <div className="w-[80vw] sm:w-[500px] px-4 pb-6">
          <p className="mt-2">{t("提交更多资料加速贷款审核")}</p>
          <Button
              onClick={()=>{
                  window.open(customerServiceData?.linkUrl)
              }}
              className="mt-6 mainColorButton"
          >
            {t('点击跳转')}
          </Button>
        </div>
      </BaseModal>
      <BaseModal
          isOpen={open}
          hideCloseButton
          onClose={onCancel}
      >
        <div className="px-4 pb-8">
          <div className="flex justify-between">
            <div className="font-bold text-xl">
              {type === 1 ? t('借贷金额') : t('还款金额')}
            </div>
          </div>

          <div className="pt-4 items-center justify-between">
            <div className="pt-4">
              <NumberInput
                  min={0}
                  value={tranche}
                  onChange={(e: any) => {
                    setTranche(e);
                  }}
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  placeholder={t('请输入金额')}
              />
            </div>
          </div>

          <button
              disabled={loading}
              onClick={(e) => {
                e.stopPropagation()
                if(type===1){
                  onOpen()
                  return
                }
                onSubmit();

              }}
              className="mainColorButton !rounded-md h-[40px] !py-0 mt-9"
          >
            {type===1 ? t('提交') : t('提交')}
          </button>

          {/*签名*/}
          <BaseSignature
              isOpen={isOpen}
              onClose={onClose}
              onSign={(signId) => {
                onSubmit({
                  signId: signId
                })
              }}
          >
            <></>
          </BaseSignature>
        </div>
      </BaseModal>
    </>
  );
}
