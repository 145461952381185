import { MaterialSymbolsAssignmentRounded } from '@/assets/icons/funds/MaterialSymbolsAssignmentRounded';
import BaseTopNav from '@/components/base/baseTopNav';
import PageEnum from '@/enums/pageEnum';
import { loanDataUrlApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@@/exports';
import { Divider } from '@nextui-org/divider';
import { Button, cn } from '@nextui-org/react';
import { history } from '@umijs/max';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Apply from './components/Apply';
import { LoanOrderModal } from './components/LoanOrderModal';
import BaseSkeleton from '@/components/base/baseSkeleton';
import { useMount } from 'ahooks';
import { VerifiedStatesEnum } from '@/enums/businessEnum';
import { toast } from '@/layouts/components/ToastMessage';

export default () => {
  const { t } = useTranslation(); // 使用翻译钩子获取翻译函数

  // 获取手续费和校验是否需要实名认证
  const {user} = useModel("user")
  useMount(() => {
    if (user?.realnameStatus !== VerifiedStatesEnum.REAL) {
      toast.error(t('请先完成实名认证'));
      history.replace(PageEnum.VERIFIED);
      return;
    }
  });

  // 弹窗认缴资金
  const [applyModal, setApplyModal] = useState(false);

  const [openAudit, setOpenAudit] = useState(false);
  const { isMobile } = useModel('system');

  // 1 借贷 ， 2还款
  const [type, setType] = useState(1);
  const { gotoCustomerService } = useModel('imSocket');
  //额度信息
  const { data: Info = {} as any, refresh,loading } = useReq(loanDataUrlApi, {
    manual: false,
  });
  return (
    <div
      className={cn(' flex flex-col justify-between', {
        'min-h-[95vh]': isMobile,
        'min-h-[85vh]': !isMobile,
      })}
    >
      <div>
        <BaseTopNav
          title={t('借贷')}
          rightNode={
            <MaterialSymbolsAssignmentRounded
              className="p-4 box-content"
              onClick={() => {
                history.push(PageEnum.LOANS_RECORD);
              }}
            />
          }
        />

        <div
          style={{
            backgroundImage: `url('${require('@/assets/loans/bg_img.png')}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            color: '#fff',
          }}
          className={cn('pt-8 pb-8 rounded-[16px] mx-4 px-1 flex justify-center items-center flex-col',{
            hidden:loading
          })}
        >
          <div className="flex flex-col items-center mb-3">
            <div className="text-white text-xl font-[600]">
              {RenderUtil.FormatAmount(Info?.loanAmount)+' USDT'}
            </div>
            <div className="pb-3 text-white pt-2 font-[400]">
              {t('总贷款额度')}
            </div>
          </div>
          <Divider className="w-[90%] !bg-backgroundAuxiliaryColor" />
          <div className="flex space-x-8 pt-6 justify-between px-2">
            <div className="flex items-center">
              <div className="text-white  text-base ml-[8px]">
                <span className="font-[600]">
                  {RenderUtil.FormatAmount(Info?.loanRemainingAmount)+' USDT'}
                </span>

                <div className="font-[400] text-white pt-1 text-xs">
                  {t('可用贷款额度')}
                </div>
              </div>
            </div>

            <div className="flex items-center">
              <div className="text-white text-base ml-[8px]">
                <span className="font-[600]">
                  {RenderUtil.FormatAmount(Info?.loanUsedAmount)+' USDT'}
                </span>

                <div className="font-[400] text-white pt-1 text-xs">
                  {t('已用用贷款额度')}
                </div>
              </div>
            </div>
          </div>
        </div>
        <BaseSkeleton
          isLoaded={loading}
        >
          <div
            style={{
              backgroundImage: `url('${require('@/assets/loans/bg_img.png')}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              color: '#fff',
            }}
            className="pt-8 pb-8 rounded-[16px] mx-4 h-[212px]"
          >

          </div>
        </BaseSkeleton>

      </div>

      <div className="pt-6 px-4">
        <Button
          onClick={() => {
            setType(1);
            setApplyModal(true);
          }}
          className="mainColorButton !rounded-md h-[48px] !py-0 w-full "
        >
          {t('我要借钱')}
        </Button>

        <Button
          onClick={() => {
            setType(2);
            setApplyModal(true);
          }}
          className="!bg-backgroundAuxiliaryTextColor mt-5 !rounded-md h-[48px] !py-0 w-full "
        >
          {t('我要还款')}
        </Button>
        {/*<Button*/}
        {/*    onClick={() => {*/}
        {/*      gotoCustomerService()*/}
        {/*    }}*/}
        {/*    className="!bg-backgroundAuxiliaryTextColor mt-5 !rounded-md h-[48px] !py-0 w-full font-[600]"*/}
        {/*>*/}
        {/*  {t('联系客服')}*/}
        {/*</Button>*/}
      </div>

      {/*申请贷款 还款*/}
      <Apply
        key="1"
        open={applyModal}
        // data={data}
        onSuccess={() => {
          refresh();
          setApplyModal(false);
        }}
        onCancel={() => {
          setApplyModal(false);
        }}
        type={type}
      />

      {/*贷款历史*/}
      <LoanOrderModal
        key="1"
        open={openAudit}
        // data={data}
        onSuccess={() => {
          setOpenAudit(false);
        }}
        onCancel={() => {
          setOpenAudit(false);
        }}
      />
    </div>
  );
};
