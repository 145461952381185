import BaseModal, { IProps } from '@/components/base/baseModal';
import { useRef } from 'react'; // 引入签名组件
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import {  uploadSignatureApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';

import { compressImage } from '@/components/base/baseUpload/util';
import { MaterialSymbolsArrowBack } from '@/assets/icons/layout/MaterialSymbolsArrowBack';

export default (props: IProps & { onSign: (signId: string) => void,onOpen?: ()=>void }) => {
  const { t } = useTranslation();
  const { onSign } = props;
  const sigCanvasRef = useRef<any>();

  const convertBase64ToFile = (base64: string, fileName: string): File => {
    // 去除 base64 字符串中的头部信息，只保留逗号后面的部分
    const parts = base64.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const imageBase64 = parts[1];

    // 解码 base64 字符串
    const byteCharacters = atob(imageBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // 创建 Blob 对象
    const blob = new Blob([byteArray], { type: imageType });

    // 转换 Blob 为 File
    return new File([blob], fileName, { type: imageType });
  };

  // 上传签名
  const {
    runAsync: uploadSignatureReq,
    loading: loadingSignat,
  } = useReq(uploadSignatureApi, {
    manual: true,
    defaultValue: [],
    loadingDefault:false,
    formatResult(res: any) {
      return res.data ?? [];
    },
  });

  return (
    <div>
      <div onClick={(e) => {
        props?.onOpen?.()
        e.stopPropagation()
      }}>
        {props.children}
      </div>
      <BaseModal
        title={t('签名')}
        classNames={{
          title:'pb-2'
        }}
        leftNode={
          <MaterialSymbolsArrowBack className="text-xl font-bold px-4 box-content" onClick={props.onClose}/>
        }
        {...props}
      >
        <div className='px-4 pb-2'>
          <div className='w-full'>
            <SignatureCanvas
              penColor="black" // 笔刷颜色
              minWidth={2} // 笔刷粗细
              maxWidth={3} // 笔刷粗细
              ref={sigCanvasRef} // 用于调用插件的API
              canvasProps={{
                className: 'h-[240px]  w-full bg-[#F7F8FA]', // 类名，设置样式
              }}
            />
            <button
              disabled={loadingSignat}
              onClick={async () => {
                const file = convertBase64ToFile(sigCanvasRef.current.toDataURL(),'signature.png')
                const reqFile = await compressImage(file);
                // 这里是上传文件的逻辑，例如使用 fetch API 或 Axios
                const formData = new FormData();
                formData.append('file', reqFile as File);
                const signId =  await uploadSignatureReq(formData)
                onSign(signId as string)
                props.onClose()
              }}
              className="w-full h-[40px] rounded-xl my-3 bg-primary text-[#fff] flex items-center justify-center"
            >
              <div className="text-xs">
                <div>{t('完成')}</div>
              </div>
            </button>
            <button
              disabled={loadingSignat}
              onClick={async () => {
                sigCanvasRef.current.clear()
              }}
              className="w-full h-[40px] rounded-xl my-3 bg-backgroundAuxiliaryColor dark:!bg-backgroundAuxiliaryTextColor text-backContrastColor flex items-center justify-center"
            >
              <div className="text-xs">
                <div>{t('清除')}</div>
              </div>
            </button>
          </div>

        </div>
      </BaseModal>
    </div>
  );
};
