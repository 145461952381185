import {BaseLoadingDisplay} from '@/components/base/baseLoadingDisplay';
import BaseModal from '@/components/base/baseModal';
import PullToRefresh from '@/components/base/basePullToRefresh';
import {usePageList} from '@/hooks/usePageList';
import {loanPageApi} from '@/services/api/requestApi';
import RenderUtil from '@/utils/RenderUtil';
import {Divider} from '@nextui-org/react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {usePaginationPage} from "@/hooks/usePaginationPage";

export function LoanOrderModal(props: any) {
    const {title, open, onCancel, ...reset} = props;

    const {t} = useTranslation();

    const [params, setParams] = useState({
        pageNo: 1,
        pageSize: 10,
    });
    const {list, loading, loadMore, getList, total, error, ...resetPageProps} = usePaginationPage(
        {
            fn: loanPageApi,
            params,
            setParams
        }
    );

    const statusAudit: any = {
        null: <span className="ml-2 text-auxiliaryTextColor">{t('待审核')}</span>,
        0: <span className="ml-2 text-auxiliaryTextColor">{t('待审核')}</span>,
        1: <span className="ml-2 text-successColor">{t('成功')}</span>,
        '-1': <span className="ml-2 text-errorColor ">{t('失败')}</span>,
    };

    useEffect(() => {
        getList();
    }, [open]);
    return (
        <BaseModal
            isOpen={open}
            hideCloseButton
            onClose={onCancel}
            classNames={{modal: 'h-[400px] overflow-y-auto'}}
        >
            <div className="text-[#666]">
                <PullToRefresh
                    loadMore={loadMore}
                    total={total}
                    list={list}
                    error={!!error}
                    getList={getList!}
                    loading={false}
                    {...resetPageProps}
                >
                    <BaseLoadingDisplay loading={loading} list={list}/>
                    {list?.map((item: any) => (
                        <div id="pull-dom" className="px-3 py-2 h-full" key={item?.id}>
                            <div className="bg-background">
                                <div className="flex justify-between">
                  <span className="">
                    {RenderUtil.formatDate(item?.createTime,"YYYY-MM-DD HH:mm:ss")}
                  </span>
                                    <div>{statusAudit[item?.status]}</div>
                                </div>
                                <div className="pt-2 pb-2">
                                    {t('申请') + (+item?.type === 1 ? t('借贷') : t('还款')) + ':'}
                                    <span className="font-bold  ml-2 text-cardBackground1">
                    {RenderUtil.FormatAmount(item?.cost)}
                  </span>
                                </div>
                                <Divider className='!bg-backgroundAuxiliaryColor'/>
                            </div>
                        </div>
                    ))}
                </PullToRefresh>
            </div>
        </BaseModal>
    );
}
